<script lang="ts" setup>
import ProductCard from '~/components/cards/ProductCard.vue'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import constants from '~/constants'
import Page from '~/enums/page'

const router = useRouter()

const props = withDefaults(
  defineProps<{
    isConsultationsSection?: boolean
    path?: string | null
    productsData: any[]
    queryParameters?: Record<string, string>
    subtitle?: string | null
    title?: string | null
  }>(),
  {
    isConsultationsSection: false,
    path: null,
    queryParameters: () => ({}),
    subtitle: null,
    title: null
  }
)

const allProductsPath = computed(() => {
  const { path, queryParameters } = props

  if (!path) {
    return null
  }

  const queryString = (new URLSearchParams(queryParameters)).toString()

  return `${path}${queryString ? `?${queryString}` : ''}`
})

function goToAllProducts (): void {
  if (typeof allProductsPath.value === 'string') {
    router.push(allProductsPath.value)
  }
}

async function goToPartnerPage (): Promise<void> {
  await router.push(Page.Partner)
}
</script>

<template>
  <section v-if="productsData.length" class="products-section">
    <div class="container">
      <div v-if="title" class="mb-6 flex items-center justify-between">
        <span class="products-section__title flex items-center">
          <NuxtLink v-if="path" :to="allProductsPath">
            <span>{{ title }}</span>
          </NuxtLink>
          <span v-else>{{ title }}</span>
        </span>

        <BasicButton
          v-if="path"
          :aria-label="title"
          class="products-section__arrow-icon"
          color="blue"
          icon="outlined/east"
          is-icon-only
          is-small
          @click="goToAllProducts"
        />
      </div>

      <p v-if="subtitle" class="mb-6">
        {{ subtitle }}
      </p>
    </div>

    <Gallery :options="constants.galleryOptions">
      <ProductCard
        v-for="x in productsData"
        :key="x.id"
        class="swiper-slide max-3-slides"
        :product-data="x"
      />
    </Gallery>

    <div v-if="isConsultationsSection" class="container mt-6">
      <BasicButton color="green" is-uppercase title="Стать специалистом" @click="goToPartnerPage" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/css/gallery';
@import 'assets/css/variables';

.products-section {
  &__arrow-icon {
    margin-left: 10px;

    &:hover {
      color: $main-blue-color;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;

    @media screen and (max-width: 600px) {
      font-size: 24px;
      line-height: 28px;
    }

    > a {
      color: inherit;
    }
  }
}
</style>
